import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { Box} from "@mui/material";
import Card from "../blog/CardSmall";
import { fetchBlogsByIds } from '../../utils/api/api';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HorizontalBanner: any = ({blogs}) => {

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 1024 },
          items: 3, // Define visible items for large screens
        },
        desktop: {
          breakpoint: { max: 1024, min: 768 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 800, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      };


    return (
<div style={{
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  paddingTop: "70px",
  maxWidth: "850px",
  margin: "0 auto",
  width: "100%"
}}>
      {blogs.length > 0 ? (
    <Carousel
    responsive={responsive}
    infinite={true}
    autoPlay={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
    customTransition="transform 300ms ease-in-out"
    focusOnSelect={true}
    centerMode={false}
    // showDots={true}
    arrows
>
    {blogs.map((item) => (
        <Box
            key={item.id}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                margin: "0 auto",
                marginBottom: "30px",
            }}
        >
            <Link to={`/${item.author?.username || "unknown"}/${item.id}`}>
                <Card blog={item} />
            </Link>
        </Box>
    ))}
</Carousel>
) : (
    <p>No blogs found</p> // 🔍 Debugging output
)}

        </div>
    );
};

export default HorizontalBanner;

