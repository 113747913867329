import { FunctionComponent, useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, IconButton } from '@mui/material';
import { motion } from 'framer-motion'
import { Heart, Lightbulb,} from 'lucide-react'

import { useNavigate, useLocation } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';
import HorizontalBanner from "../components/landing/HorizontalBanner";

import Navbar from "../components/main/Navbar";
// import Header from "../components/blog/Header";
import KoFiButton from "../components/landing/KoFi";
import LazyShow from "../components/landing/LazyShow";
import VideoPlayerList from "../components/landing/Interviews";
import FAQ from "../components/landing/FAQ";
import Footer from "../components/main/Footer";
import { fetchBlogsByIds } from '../utils/api/api';

import { AuthContext } from '../components/authentification/AuthContext';





const PillarItem = ({ title, description, icon, index, pillar }) => {

  const isEven = index % 2 === 0

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`flex flex-col md:flex-row ${isEven ? '' : 'md:flex-row-reverse'} items-center justify-center pt-4`}
    >
      <motion.div
        className="w-full md:w-1/2 p-4 md:p-8"
        initial={{ x: isEven ? -50 : 50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="flex items-center mb-4">
          <div className="bg-gray-100 p-2 rounded-full mr-4 mt-[15px]">
            {icon}
          </div>
          <h2 className="text-2xl md:text-3xl font-bold text-primary">{title}</h2>
        </div>
        <p className="text-gray-600 text-base md:text-lg">{description}</p>
        <div className="w-full max-w-md relative mt-4 border-1 border-primary rounded-[10px]">
        <div className="flex flex-row gap-2 justify-start items-center p-5">
<a href="https://apps.apple.com/be/app/slostr/id6590637281?itscg=30200&itsct=apps_box_badge&mttnsubad=6590637281" style={{display: "inline-block"}}>
    <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us?releaseDate=1722902400" alt="Download on the App Store" style={{width: '160px', height: '46px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.slostr.Slostr&hl=en" style={{display: "inline-block"}}>
    <img src="./GetItOnGooglePlay_Badge_Web_color_English.png" alt="Download on the Play Store" style={{width: '143px', height: '41px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
</div>
<div className="absolute top-0 right-0 bg-primary text-white font-bold py-1 px-3 rounded-bl-lg rounded-tr-lg">
              BETA
            </div>
          </div>
      </motion.div>
      <motion.div
        className="w-full md:w-1/2"
        initial={{ scale: 0.9, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <PillarCarousel pillar={pillar} />
      </motion.div>
    </motion.div>
  )
}

export const PillarCarousel = ({ pillar }) => {
  const { t } = useTranslation();

  const pillarData = {
    "1": [
      {
        id: "01",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-friends.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-friends.jpg",
        description: t("pillar-inspire-1"),
      },
      {
        id: "02",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-map.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-map.jpg",
        description: t("pillar-inspire-2"),
      },
      {
        id: "03",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-profile.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-profile.jpg",
        description: t("pillar-inspire-3"),
      },
    ],
    "2": [
      {
        id: "01",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-trains.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-trains.jpg",
        description: t("pillar-practical-1"),
      },
      {
        id: "02",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-climbing.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-climbing.jpg",
        description: t("pillar-practical-2"),
      },
      {
        id: "03",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-pois.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-pois.jpg",
        description: t("pillar-practical-3"),
      },
    ],
    "3": [
      { id: "01", videoSrc: null, description: null },
      { id: "02", videoSrc: null, description: null },
      { id: "03", videoSrc: null, description: null },
    ],
  };

  const [currentIndex, setCurrentIndex] = useState(0)
  const contents = pillarData[pillar]
  const videoRefs = useRef([])

  useEffect(() => {
    try {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          if (index === currentIndex) {
            video.play().catch(() => {}); // Catch and ignore play errors
          } else {
            video.pause();
            video.currentTime = 0;
          }
        }
      });
    } catch (error) {
      // Ignore errors silently
    }
  }, [currentIndex]);


  const handleCardClick = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <section className="w-full max-w-xs mx-auto md:max-w-sm relative pb-[90px] md:pb-0">
      <div className="relative">
        {contents.map((content, index) => (
          <motion.div
            key={content.id}
            className={`
              h-full max-h-[300px] md:max-h-[500px] w-[50%] md:w-3/5 absolute inset-0 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
              ${getPositionClasses(index, currentIndex, contents.length)}
            `}
            style={{
              zIndex: getZIndex(index, currentIndex, contents.length),
            }}
            onClick={() => handleCardClick(index)}
            initial={false}
            animate={{
              scale: index === currentIndex ? 1 : 0.9,
              x: getTranslateX(index, currentIndex, contents.length),
            }}
            transition={{ duration: 0.5 }}
          >
            {content.videoSrc ? (
            <div className="bg-white rounded-lg shadow-2xl cursor-pointer transition-transform hover:scale-105  flex flex-col  overflow-hidden">
            <div className="relative flex-grow">
            <video
    ref={(el) => (videoRefs.current[index] = el)}
    className="w-full h-full object-cover"
    src={content.videoSrc}
    loop
    muted
    playsInline
    poster={content.thumbnail}
  />

              <div className="bg-white relative bottom-[10px] md:bottom-[14px] h-4"></div>
            </div>
            <div className="p-4 mt-[-5px] md:mt-[-15px] pt-0">
              <p className="text-sm text-gray-600 text-center">{content.description}</p>
            </div>
          </div>
            ) : (
              <div className="bg-white rounded-lg shadow-2xl cursor-pointer transition-transform hover:scale-105  flex flex-col  overflow-hidden w-36 h-36">
              <div className="relative flex-grow ">
              <div
                className="w-full h-full object-cover"
              />
            </div>
            </div>
            )}

          </motion.div>
        ))}
      </div>
    </section>
  )
}

function getPositionClasses(index: number, currentIndex: number, totalItems: number): string {
  const diff = (index - currentIndex + totalItems) % totalItems
  return diff === 0 ? 'relative' : 'absolute'
}

function getZIndex(index: number, currentIndex: number, totalItems: number): number {
  const diff = (index - currentIndex + totalItems) % totalItems
  return totalItems - Math.abs(diff)
}

function getTranslateX(index: number, currentIndex: number, totalItems: number): string {
  const diff = (index - currentIndex + totalItems) % totalItems

  switch (diff) {
    case 0:
      return '50%'
    case 1:
    case -2:
      return '100%'
    case -1:
    case 2:
      return '0%'
    default:
      return '0%'
  }
}


const activityOptions = [
  {
    id: "climbing",
    icon: "icons/icon-climbing.svg",
    label: "climbing-spots",
    description: "climbing-spots-explanation",
    mapLink: "/climbing-spots",
    welcomeImg: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/climbing-map.jpg",
    ids: [111, 386, 320, 378],
  },
  {
    id: "hike",
    icon: "icons/icon-hike.svg",
    label: "hiking-itineraries",
    description: "hiking-itineraries-explanation",
    mapLink: "/itineraries/hiking",
    welcomeImg: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/hiking-map.jpg",
    ids: [240, 228, 112],
  },
  {
    id: "bike",
    icon: "icons/icon-bike.svg",
    label: "bike-itineraries",
    description: "bike-itineraries-explanation",
    mapLink: "/itineraries/bike",
    welcomeImg: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/media/blog_header_images/header_235_1728628299603.jpg",
    ids: [288, 159, 323, 333],
  },
];

const LandingPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [bgImage, setBgImage] = useState('');
  const [selectedActivity, setSelectedActivity] = useState(activityOptions[0]);
  const [blogs, setBlogs] = useState<any[]>([]);
  const ids = [111, 386, 320, 378, 288, 159, 323, 333, 240, 228, 112];


  useEffect(() => {
    fetchBlogsByIds({ids}) // ✅ Fetch all blogs ONCE
      .then((data) => {
        console.log("Fetched Blogs:", data);
        setBlogs(data);
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  // ✅ Filter blogs based on selected activity
  const filteredBlogs = blogs.filter((blog) => selectedActivity.ids.includes(blog.id));


  const updateBackgroundImage = () => {
    if (window.innerWidth <= 768) {
      setBgImage('url("https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/about-us-phone.jpeg")');
    } else {
      setBgImage('url("https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/about-us.jpeg")');
    }
  };

  useEffect(() => {
    updateBackgroundImage(); // Set initial background image
    window.addEventListener('resize', updateBackgroundImage); // Update background image on resize

    return () => {
      window.removeEventListener('resize', updateBackgroundImage); // Cleanup event listener
    };
  }, []);
  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      // Set a delay before scrolling
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        setIsLoading(false); // Set loading to false after scrolling
      }, 250); // 2 seconds delay
    } else {
      setIsLoading(false); // Set loading to false if no hash
    }
  }, [location]);

  useEffect(() => {
    // Add the active class after a delay to trigger the animation
    const timer = setTimeout(() => {
      setIsActive(true);
    }, 10); // Delay to ensure the component is fully mounted

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);


  const scrollToNextSection = (id: any) => {
    const nextSection = document.getElementById(id);
    if (nextSection) {
      const offset = 100; // Reduce scroll position by 100px
      const elementPosition = nextSection.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  };

const whatsappNumber = "+33 7 66 52 21 63";




const pillars = [
  {
    title: t("slostr-inspire"),
    description: t("slostr-inspire-info"),
    icon: <Heart className="w-8 h-8 text-red-500" />,
    pillar: '1' as const,
  },
  {
    title: t("slostr-practical"),
    description: t("slostr-practical-info"),
    icon: <Lightbulb className="w-8 h-8 text-yellow-500" />,
    pillar: '2' as const,
  },
  // {
  //   title: t("slostr-to-come"),
  //   description: t("slostr-to-come-info"),
  //   icon: <CircleHelp className="w-8 h-8 text-blue-500" />,
  //   pillar: '3' as const,
  // },
  // {
  //   title: t("slostr-events"),
  //   description: t("slostr-events-info"),
  //   icon: <Calendar className="w-8 h-8 text-blue-500" />,
  //   pillar: '3' as const,
  // },
];



  return (
    <>
            <Navbar scrollToNextSection={scrollToNextSection}/>
            <div className="w-full relative overflow-hidden gap-[30px]"
      style={{backgroundImage: 'linear-gradient(120deg, #fffff 0%, #fffff 100%)'}}>

        {/* Hero */}
        <div className="relative w-full h-[calc(100vh)] flex items-center justify-center bg-[url('https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/slostr-bg.jpg')] bg-top">

            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>
            <div className="flex flex-col md:flex-row w-full px-10 ">
            <div className="relative z-10 text-center text-white flex flex-col justify-center w-full gap-3 md:pl-6 items-start">

            <div className="flex flex-row gap-3">
  <img src="icons/icon-train.svg" alt="" width={22} className="svg-white"/>
  <img src="icons/icon-hike.svg" alt="" width={22} className="svg-white"/>
  <img src="icons/icon-bike.svg" alt="" width={22} className="svg-white"/>
  <img src="icons/icon-climbing.svg" alt="" width={22} className="svg-white"/>
</div>

                <h1 className="text-xl md:text-3xl lg:text-3xl text-left font-[400] mt-2">{t('slostr-title')}</h1>
                <p className="text-md md:text-xl font-[100] text-left">{t('slostr-heading')}</p>
                {/* <Button
  sx={{
    background: "linear-gradient(45deg, #9dd1b6 0%, #8abfa3 100%)",
    borderRadius: "25px",
    // borderColor: 'white',
    // borderWidth: "1px",
    // borderStyle: "solid",
    color: "white",
    width: 150,
    // "&:hover": {
    //   background: "linear-gradient(45deg, #A0B985 0%, #155D57 100%)",
    // },
  }}
  variant="contained"
>
  {t("lets-go")}
</Button> */}

            </div>
            <div className="flex justify-center items-center w-full h-full z-50">
            <img src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/slostr-pres.png" alt="" className="mt-5 md:mt-0 h-[250px] md:h-[400px]"/>

            </div>
            </div>

            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer" onClick={()=> scrollToNextSection('product-showcase')}>
                    <div className="text-[20px] animate-bounce text-white">
                        <FaAngleDown />
                    </div>
                    <div className="text-[20px] animate-bounce text-white mt-[-5px]">
                        <FaAngleDown />
                    </div>
                </div>
        </div>


        <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div id="product-showcase" className="max-w-7xl mx-auto flex flex-col gap-0 w-full justify-center items-center">
          <h1 className="text-xl md:text-3xl font-bold text-center text-slate-500 mt-0 mb-4 max-w-[650px]">{t('slostr-maps-info')}</h1>

          <div className="flex flex-col items-center w-full max-w-[800px]">
      {/* Activity Buttons */}
      <div className="flex flex-row gap-3 w-full items-center justify-center">
        {activityOptions.map((activity) => (
          <Button
            key={activity.id}
            variant={selectedActivity.id === activity.id ? "contained" : "outlined"}
            onClick={() => setSelectedActivity(activity)}
            sx={{
              width: 100,
              borderRadius: "10px",
              // background: selectedActivity.id === activity.id ? "white" : "transparent",
              "& img": {
                filter: selectedActivity.id === activity.id ? "brightness(0) invert(1)" : "none",
              },
            }}
          >
            <img src={activity.icon} alt={activity.label} width={22} />
          </Button>
        ))}
      </div>

      {/* Activity Details */}
      <div className="flex flex-col gap-3 md:flex-row w-full mt-10">
  <div className="w-full flex flex-col gap-4">
    <h2 className="text-xl font-bold text-left text-slate-500">
      {t(selectedActivity.label)}
    </h2>
    <p className="text-lg font-[100]">{t(selectedActivity.description)}</p>


    {/* See Map Button - Disabled for Hiking & Cycling */}
    <Button
      sx={{
        background: ["hike", "bike"].includes(selectedActivity.id)
          ? "lightgray"
          : "linear-gradient(45deg, #9dd1b6 0%, #8abfa3 100%)",
        borderRadius: "25px",
        color: "white",
        width: 150,
      }}
      variant="contained"
      className="self-center md:self-start"
      onClick={() => {
        if (!["hike", "bike"].includes(selectedActivity.id)) {
          window.location.href = selectedActivity.mapLink;
        }
      }}
      disabled={["hike", "bike"].includes(selectedActivity.id)}
    >
      {["hike", "bike"].includes(selectedActivity.id) ? t("coming-soon") : t("see-map")}
    </Button>
  </div>

  {/* Welcome Image */}
  <div className="w-full px-3">
    <img src={selectedActivity.welcomeImg} alt="" className="rounded-xl" />
        {/* Show Extra Message for Hiking & Cycling */}
        {["hike", "bike"].includes(selectedActivity.id) && (
      <p className="text-lg pt-2 italic text-slate-600">
        {t("coming-soon-info")}
      </p>
    )}
  </div>
</div>

    </div>
          {/* Horizontal Banner */}
          <HorizontalBanner blogs={filteredBlogs} />

          <div className="max-w-7xl mx-auto flex flex-col gap-0 w-full justify-center items-center mt-12">
            <h1 className="text-3xl font-bold text-center text-slate-500 mt-0 mb-0 max-w-[650px]">{t('slostr-app')}</h1>
            <div className="mx-auto flex flex-col gap-8 w-full justify-center items-center">
            {pillars.map((pillar, index) => (
          <PillarItem
          key={index}
          title={pillar.title}
          description={pillar.description}
          icon={pillar.icon}
          index={index}
          pillar={pillar.pillar}
        />
                    ))}
            </div>

          </div>
        </div>
      </div>




    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      id="interviews"
    >
            <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex align-center flex-col"
      >
      <VideoPlayerList/>
      </motion.div>
    </motion.div>

        <LazyShow>
        <div id="about-us" className="relative bg-cover bg-center h-screen mt-10" style={{ backgroundImage: bgImage }}>
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-start pt-24 justify-center">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white text-center">
        {t('slostr-who-are-we')}
                </h2>
    </div>
    <div className="absolute bottom-0 left-0 right-0 mb-8 mx-auto max-w-3xl p-4 text-white text-center">
        <p className="text-base md:text-lg leading-6 md:leading-7">
{t('slostr-who-are-we-info')}
        </p>
    </div>
</div>


        </LazyShow>


        <LazyShow>
        <div id="next-steps" className="bg-gray-100 py-16">
    <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-center font-display text-3xl font-bold tracking-tight text-primary md:text-4xl mb-8">
            {t('slostr-next')}
        </h2>
        <p className="text-center text-lg leading-7 text-gray-700 mb-8">
        {t('slostr-next-header')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2 text-slate-700">{t('slostr-next-features')}</h3>
                <p className="text-base leading-6 text-gray-600">
                {t('slostr-next-features-info')}
                </p>
            </div>
            <div className="bg-white px-6 py-8 rounded-lg shadow-2xl relative flex justify-start items-center flex-col border-2 border-solid border-[#f88378]">
                <h3 className="text-xl font-semibold mb-2 text-slate-700">{t('slostr-next-donations')}</h3>
                <p className="text-base leading-6 text-gray-600">
                {t('slostr-next-donations-info')}
                </p>
                <div className="flex justify-center space-x-4 mt-4">
                {/* <KoFiButton /> */}
                {/* <Button onClicke={r}>
                  {t('feedback-board')}
                </Button> */}
                </div>
            </div>
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2 text-slate-700">{t('slostr-next-feedback')}</h3>
                <p className="text-base leading-6 text-gray-600">
                {t('slostr-next-feedback-info')}
                </p>
                <div className="flex justify-center space-x-4 mt-4">
        <IconButton href={`https://wa.me/${whatsappNumber}`} target="_blank" color="primary">
          <WhatsAppIcon />
        </IconButton>
        <IconButton href="mailto:vladimir.fayt@slostr.com" color="primary">
          <EmailIcon />
        </IconButton>
        <IconButton href="https://www.instagram.com/goslostr" target="_blank" color="primary">
          <InstagramIcon />
        </IconButton>
        <IconButton href="https://www.linkedin.com/in/vladimir-fayt-6507a91a1/" target="_blank" color="primary">
          <LinkedInIcon />
        </IconButton>
      </div>
            </div>
        </div>
    </div>
    {/* <div className="flex justify-center items-center p-10 w-full">
    <img src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/app-showcase.png" alt="" className="sm:max-w-[400px]"/>
    </div> */}

<div className="flex flex-row gap-2 justify-center items-center p-10">
<a href="https://apps.apple.com/be/app/slostr/id6590637281?itscg=30200&itsct=apps_box_badge&mttnsubad=6590637281" style={{display: "inline-block"}}>
    <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us?releaseDate=1722902400" alt="Download on the App Store" style={{width: '160px', height: '46px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.slostr.Slostr&hl=en" style={{display: "inline-block"}}>
    <img src="./GetItOnGooglePlay_Badge_Web_color_English.png" alt="Download on the Play Store" style={{width: '143px', height: '41px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
</div>

</div>
        </LazyShow>

        <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      id="faq"
    >
            <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
      <FAQ />
      </motion.div>
    </motion.div>


        <Footer />
        {/* Inline styles for keyframes animation */}
        <style>
          {`
            @keyframes scroll-down {
              0%, 100% { transform: translate(-50%, -5px) rotate(45deg); opacity: 0; }
              50% { transform: translate(-50%, 0) rotate(45deg); opacity: 1; }
            }
          `}
        </style>
      </div>
    </>


    )
};

export default LandingPage;
