import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {NextUIProvider} from "@nextui-org/react";

import "./global.css";

import { ErrorProvider } from "./components/errors/ErrorContext";

import ErrorBoundary from "./components/errors/ErrorBoundary";
import { NotificationProvider } from "./components/NotificationContext";
import { AuthProvider } from "./components/authentification/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react"; // Static import for Sentry

// Function to load external script
function loadScript(src: any) {
  const script = document.createElement("script");
  script.src = src;
  script.crossOrigin = "anonymous";
  document.head.appendChild(script);
}

if (process.env.REACT_APP_DEBUG === "false") {
  loadScript(
    "https://js-de.sentry-cdn.com/bf601eea76e77e6218649ebbc6a8b443.min.js"
  );
    Sentry.init({
      dsn: "https://bf601eea76e77e6218649ebbc6a8b443@o4507126445178880.ingest.de.sentry.io/4507126448914512",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/slostr\.com\//],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const container = document.getElementById("root");
const root = createRoot(container!);



root.render(
  <NextUIProvider>
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <ErrorProvider>
        <ErrorBoundary>
          <AuthProvider>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
            >
              <I18nextProvider i18n={i18n}>
                <NotificationProvider>
                  <App />
                </NotificationProvider>
              </I18nextProvider>
            </GoogleOAuthProvider>
          </AuthProvider>
        </ErrorBoundary>
      </ErrorProvider>
    </BrowserRouter>
  </ThemeProvider>
  </NextUIProvider>
);

